import styled from "@emotion/styled";
import React from "react";
import senseImage from "../../assets/img/studio/space_image.png";

export default function SpaceSection() {
    return (
        <Container>
            <div className="bl-sense cover-image">

                <div className="bl-sense-wrap">
                    <span className="sense-title-first">「余白」の空間を</span>
                    <span className="sense-title-second">布と身体との間につくりだす</span>
                    <p>
                        ワークウエア作りにおいて一貫して試みてきたのは、<br className="hideSP"/>
                        意図的に「余白」の空間を布と身体との間につくりだすことです。<br className="hideSP"/>
                        ヤコブソンの構造言語学の中の「縮減」という考えがある。<br className="hideSP"/>
                        情報のある部分を消していって、大切な部分だけを取りだして、縮小させていく。<br className="hideSP"/>
                        それらを記号にし、言語が作られるらしい。<br/>
                        <br className="hideSP"/>
                        私が試みていることは、まさにヤコブソンの言う縮減に似ている。<br className="hideSP"/>
                        立体的な身体の上に布で余白を作ることで、身体の情報の縮減を行う。<br className="hideSP"/>
                        ヒトを記号化し大切な部分が引き出される。<br className="hideSP"/>
                        そうして身体的情報を削いでいった先に、ヒトの魅力が現れるのではないだろうかと考えている。<br className="hideSP"/>
                        この縮減という考えは、日本の哲学者 九鬼周造氏の言う「いき」に通じるものがある。<br className="hideSP"/>
                        削ぎ落とし、どんどん漉いていく。大なるものが小なるものに転移していく。<br className="hideSP"/>
                        漉いて漉いてこれでいいと思うところでやめる。<br className="hideSP"/>
                        その頂点にたちのぼるものが粋であり、数寄や芭蕉の「さび」であり、日本特有の美学のようにも感じる。<br/>
                    </p>
                </div>
            </div>

        </Container>
    );
}

const Container = styled.div`

  .cover-image {

    margin-top: 200px;
    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1ch;

    p {
      padding: 30px;
      //font-family: YuMincho;
      font-weight: 500;
      font-size: 15px;
      letter-spacing: 0.08em;
      line-height: 37px;
      text-align: left;
      color: #fff;

      @media (max-width: 768px) {
        line-height: 34px;
        padding: 20px;
      }

    }

    .philosophy-image {
      margin: 20px;
    }
  }


  .bl-sense {
    background-image: url(${senseImage});
    background-position-x: center;
    background-position-y: center;
    background-size: cover;
    min-height: max(100vh, 840px);
    max-height: 1060px;
    //min-height: 840px;
    width: 100%;

    .bl-sense-wrap {

      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: start;

      padding-top: 130px;
      padding-bottom: 130px;
    }

    .sense-title-first,
    .sense-title-second {
      //font-family: YuMincho;
      font-weight: 500;
      font-size: 54px;
      letter-spacing: 0.08em;
      line-height: 75px;
      text-align: left;
      color: #fff;

      @media (max-width: 768px) {
        font-size: 6.2vw;
        line-height: 14vw;
      }
    }

    .sense-title-first {
      padding-left: 0px;
      padding-top: 30px;

      @media (max-width: 768px) {
        padding-left: 2.2vw;
      }

    }

    .sense-title-second {
      padding-left: 30px;

      @media (max-width: 768px) {
        padding-left: 6.2vw;
      }
    }
  }

`;
