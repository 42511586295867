import {StaticImage} from "gatsby-plugin-image";
import React, {useEffect, useState} from "react";
import Layout from "../../components/layout";
import styled from "@emotion/styled";
import PageTitle from "../../components/common/PageTitle";
import Quote from "../../components/common/Quote";
import BoarderSectionTitle from "../../components/common/BoarderSectionTitle";
import StoreProductImage from "../../components/pages/product/StoreProductImage";
import ItemListBlock from "../../components/pages/ItemListBlock";
import SpaceSection from "../../components/pages/SpaceSection";
import AppearanceSection from "../../components/pages/AppearanceSection";
import CoverView2 from "../../components/common/CoverView2";
import MaintenanceSection from "../../components/pages/MaintenanceSection";

const scrollTop = (): number => {
    return Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop);
};

export default function Index() {

    const ref = React.createRef<HTMLDivElement>()
    const [isWhite, setIsWhite] = useState<boolean>(true);

    const onScroll = (): void => {
        if (ref!.current!.getBoundingClientRect().top < window.innerHeight/2) {
            setIsWhite(true);
        } else {
            setIsWhite(false);
        }
    };

    useEffect(() => {
        document.addEventListener("scroll", onScroll);
        return (): void => document.removeEventListener("scroll", onScroll);
    });

    return (
        <Layout>
            <Container isWhite={isWhite}>
                <CoverView2 image={
                    <StaticImage
                        src="../../assets/img/workwear/workwear_cover.jpg"
                        alt="workwear top"
                        objectFit="cover"
                        layout="fullWidth"
                        quality={100}
                        className="top-cover"
                    />
                }>
                    <StaticImage
                        src={"../../assets/img/workwear/cover_title.png"}
                        alt="所作と佇まいを美しく"
                        className="cover-title"
                        placeholder="tracedSVG"

                        width={188}
                        height={203}
                    />
                </CoverView2>

                <div className="page-title">
                    <PageTitle title="WORK WEAR"/>
                </div>

                <div className="bl-quote">
                    <Quote cite="解剖学者 三木成夫氏">
                        立つ、ということは、もっとも美しく<br/>
                        それはまた人の心の、ひとつ極まったものである。
                    </Quote>

                    <Quote cite="解剖学者 三木成夫氏" fontSize="24px">
                        どの国の言葉をみても例外なく ‘直立’ に ‘正’ の意味が込められていると<br/>
                        いう。平衡のとれたひとつの瞬間に、人の心は無意識にひきよせられる。
                    </Quote>
                </div>

                <AppearanceSection/>

                <div className="bl-images">
                    <div className="grid-1">
                        <StaticImage
                            src="../../assets/img/photo/2021043013652.jpg"
                            alt="workwear"
                            className="grid-image"
                            quality={100}
                        />
                    </div>
                </div>
                <SpaceSection/>

                <div className="bl-images">
                    <div className="grid-1">
                        <StaticImage
                            src="../../assets/img/photo/2021051213804.jpg"
                            alt="workwear"
                            className="grid-image"
                            quality={100}
                        />
                    </div>
                    <div className="grid-2">
                        <StaticImage
                            src="../../assets/img/photo/2021051213969.jpg"
                            alt="workwear"
                            className="grid-image"
                            objectFit="contain"
                            objectPosition="top"
                            quality={100}
                        />
                        <StaticImage
                            src="../../assets/img/photo/2021051214073.jpg"
                            alt="workwear"
                            className="grid-image"
                            objectFit="contain"
                            objectPosition="top"
                            quality={100}
                        />
                    </div>
                    <div className="grid-2 slide_up">
                        <StaticImage
                            src="../../assets/img/photo/2021043013678.jpg"
                            alt="workwear"
                            className="grid-image"
                            objectFit="contain"
                            objectPosition="top"
                            quality={100}
                        />
                        <StaticImage
                            src="../../assets/img/photo/2021051213284.jpg"
                            alt="workwear"
                            className="grid-image"
                            objectFit="contain"
                            objectPosition="top"
                            quality={100}
                        />
                    </div>
                    {/*<div className="grid-1">*/}
                    {/*    <StaticImage*/}
                    {/*        src="../../assets/img/photo/2021051214160.jpg"*/}
                    {/*        alt="workwear"*/}
                    {/*        className="grid-image"*/}
                    {/*        objectFit="contain"*/}
                    {/*        objectPosition="top"*/}
                    {/*        quality={100}*/}
                    {/*    />*/}
                    {/*</div>*/}

                    <div className="grid-2">
                        <StaticImage
                            src="../../assets/img/photo/2021051213145.jpg"
                            alt="workwear"
                            className="grid-image"
                            objectFit="contain"
                            objectPosition="top"
                            quality={100}
                        />
                        <StaticImage
                            src="../../assets/img/photo/2021051213131.jpg"
                            alt="workwear"
                            className="grid-image"
                            objectFit="contain"
                            objectPosition="top"
                            quality={100}
                        />
                    </div>
                </div>

                {/*<Link to="/workwear/gallery" className="gallery-link">*/}
                {/*    <StaticImage*/}
                {/*        src="../../assets/img/workwear/gallery.png"*/}
                {/*        alt="workwear"*/}
                {/*        className="grid-image"*/}
                {/*        objectFit="contain"*/}
                {/*        objectPosition="top"*/}
                {/*    />*/}
                {/*</Link>*/}

                <div className="bl-product-list" ref={ref}>
                    <BoarderSectionTitle title="Product"/>

                    <div className="products">

                        <StoreProductImage
                            name="Women's Coat"
                            price="¥44,550"
                            link_to="https://shop.knitology.net/products/%E3%82%B3%E3%83%BC%E3%83%88">
                            <StaticImage
                                src="../../assets/img/item/2021090822723.webp"
                                alt="Women's Coat"
                                className="item-image"
                                quality={100}
                            />
                        </StoreProductImage>

                        <StoreProductImage
                            name="Women's Jacket"
                            price="¥37,950"
                            link_to="https://shop.knitology.net/products/womens-jacket">
                            <StaticImage
                                src="../../assets/img/item/2021090822725.webp"
                                alt="Women's Jacket"
                                className="item-image"
                                quality={100}
                            />
                        </StoreProductImage>

                        <StoreProductImage
                            name="Women's Tapered Pants"
                            price="¥32,450"
                            link_to="https://shop.knitology.net/">
                            <StaticImage
                                src="../../assets/img/item/2021090822750.webp"
                                alt="Women's Tapered Pants"
                                className="item-image"
                                quality={100}
                            />
                        </StoreProductImage>

                    </div>
                    <ItemListBlock/>
                </div>

                <MaintenanceSection />
            </Container>
        </Layout>
    );
}

const Container = styled.div<{ isWhite: boolean }>`
  
  background-color: ${(props) => props.isWhite ? "#B9B9B9" : "#000"};
  transition: all 600ms 0s ease;

  .page-title {
    margin: 160px auto;
  }

  .bl-images {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    max-width: 3440px;
    margin: 0 auto 200px;

    .grid-image {
      margin-top: 120px;
      width: 100%;
      break-inside: avoid;

      @media (max-width: 768px) {
        margin-top: 0px;
      }
    }
  }

  .grid-1 {
    .grid-image {
      //width: 90%;
      margin: 120px auto;
      max-width: 1000px;
      break-inside: avoid;

      display: flex;
      justify-content: space-between;
      align-items: center;

      @media (max-width: 768px) {
        margin: 40px auto
      }
    }
  }

  .grid-2 {

    width: 100%;
    max-width: 1440px;
    margin: 40px auto;
    padding: 0 40px;

    display: grid;
    grid-gap: 40px;
    grid-template-columns: repeat(2, 1fr);

    > *:nth-of-type(2) {
      margin-top: 46vw;
    }

    &.slide_up {
      margin: max(-500px, -36vw) auto 0;
    }

    @media (max-width: 768px) {
      margin: 0px auto;
      padding: 0 0px;
      grid-gap: 20px;
    }
  }

  .gallery-link {
    margin: 200px auto 400px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }

  .bl-product-list {
    margin-top: 200px;
  }

  .products {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    margin: 100px auto;
    max-width: 1440px;
    width: 100%;
    padding: 0 20px;

    overflow-x: auto; /* 横スクロールの指定 */
    white-space: nowrap; /* 横スクロールの指定 */
    overflow-scrolling: touch; /* スクロールを滑らかにする */
  }

  .bl-online-desc {
    display: block;
    color: #fff;
    display: grid;
    place-content: center;
    gap: 1ch;
    text-align: right;
    //flex: 1 1 200px;
  }

}
`