import {StaticImage} from "gatsby-plugin-image";
import React from "react";
import styled from "@emotion/styled";
import CoverView2 from "../../components/common/CoverView2";

export default function AppearanceSection() {
    return (
        <Container>
            <CoverView2 sp_slide="75%" image={
                <StaticImage
                    src="../../assets/img/workwear/bg0.jpg"
                    alt="appearance_image"
                    objectFit="cover"
                    layout="fullWidth"
                    className="backimage"
                    quality={100}
                />
            }>
                <div className="inner-image">
                    <StaticImage src="../../assets/img/workwear/stand_message.png"
                                 alt="人間はたってるだけで美しい"
                                 quality={100}
                    />
                    <p className="bl-philosophy-text is-hide">
                        まさに私は無意識に直立の姿の美しさに引き寄せられている。<br/>
                        <span className="big-span">人間は立っているだけで、美しい。</span><br/><br/>
                        人体は進化の過程で最高のバランスを獲得し、地面に直立することができる。<br/>
                        人体を読み解くと、当たり前の動作が、そしてその存在そのもが、<br/>
                        いかに究極の美しい構造を成しているのかがわかる。<br/>
                        ヒトがただ立っている。その姿を最高なものにしたい。<br/>
                        人類が誕生し、現代人の原型となっている<span className="big-span">旧石器時代200万年の時を経ても</span><br/>
                        <span className="big-span">身体構造の基本は何も変わらない。</span><br/>
                        その神秘に満ちた優美な直立の姿を、<br/>
                        ほんの少しだけ押し上げるような、<br/>
                        佇まいを美しくするような、<br/>
                        そんな服を目指したい。<br/>
                    </p>
                </div>
            </CoverView2>

        </Container>
    );
}

const Container = styled.div`

  .inner-image {

    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    height: 100vh;
    margin-right: auto;
    min-height: 880px;
    padding-left: max(30px, 3%);
    width: calc(100% - max(30px, 3%));

    .sense-title-first {
      padding: 0 30px;
      //font-family: YuMincho;
      font-weight: 500;
      font-size: 36px;
      letter-spacing: 0.08em;
      line-height: 75px;
      text-align: left;
      color: #fff;
    }

    .bl-philosophy-text {
      //max-width: 00px;
      //font-family: YuMincho;
      font-weight: 500;
      font-size: 17px;
      letter-spacing: 0.08em;
      line-height: 42px;
      text-align: left;
      color: #fff;
    }


    @media (max-width: 768px) {

      align-items: center;
      padding: 0 10px;
      width: calc(100% - 20px);
      margin-right: 0;
    }
  }
`