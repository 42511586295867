import React from "react";
import styled from "@emotion/styled";
import BoarderSectionTitle from "../../components/common/BoarderSectionTitle";
import {StaticImage} from "gatsby-plugin-image";


export default function MaintenanceSection() {

    return (
        <Container>
            <BoarderSectionTitle title="CARE"/>
            <h4 className="section-title-jp">日々のメンテナンス</h4>
            <p className="section-desc">長く着用いただくために、<br className="hidePC"/> 日々の取扱方法をご紹介します。</p>
            <div className="maintenance">
                <div className="maintenance-bg">
                    <StaticImage
                        src="../../assets/img/workwear/maintenance_pc.png"
                        alt="workwear top"
                        objectFit="cover"
                        layout="fixed"
                        quality={100}
                        className="maitaenance-image hideSP"
                    />
                </div>
                <StaticImage
                    src="../../assets/img/workwear/maintenance0.png"
                    alt="workwear top"
                    objectFit="contain"
                    layout="constrained"
                    quality={100}
                    className="hidePC maitenance_sp_image"
                />
                <div className="maintenance-prot-desc content__0">
                    <span className="maintenance-topic-title">アイロン</span>
                    <ul>
                        <li className="maintenance-topic-list">
                            定期的にスチーマー又はスチームアイロンをすると張りが蘇り綺麗になります。表側からスチームを当ててください。
                        </li>
                    </ul>
                </div>
                <StaticImage
                    src="../../assets/img/workwear/maintenance1.png"
                    alt="workwear top"
                    objectFit="contain"
                    layout="constrained"
                    quality={100}
                    className="hidePC maitenance_sp_image"
                />
                <div className="maintenance-prot-desc content__1">
                    <span className="maintenance-topic-title">洗濯</span>
                    <ul>
                        <li className="maintenance-topic-list">
                            小さめのネットに入れ、オシャレ着用洗剤を使用して手洗いコースでお洗ください。
                        </li>
                        <li className="maintenance-topic-list">
                            上着はハンガーがけをせずに、平干し又は洗濯ポール等に広げて干してください。
                        </li>
                        <li className="maintenance-topic-list">
                            パンツは保管時時の様に裾側をハンガーで吊って干すとシワが重みで取れます。
                        </li>
                        <li className="maintenance-topic-list">
                            その際、センタータック部分を手でパンパン叩くと、ラインがより綺麗に出ます。
                        </li>

                    </ul>
                </div>
                <StaticImage
                    src="../../assets/img/workwear/maintenance2.png"
                    alt="workwear top"
                    objectFit="contain"
                    layout="constrained"
                    quality={100}
                    className="hidePC maitenance_sp_image"
                />
                <div className="maintenance-prot-desc content__2">
                    <span className="maintenance-topic-title">保管</span>
                    <ul>
                        <li className="maintenance-topic-list">
                            パンツは裾をハンガー側に吊って干すとセンタータックのシワが伸びて良いです
                        </li>
                        <li className="maintenance-topic-list">
                            上着はニット用ハンガーを使用して肩がズレ落ちないようにハンガーがけをします
                        </li>
                    </ul>
                </div>
            </div>

            <div className="maintenance_contact">
                <p className="maintenance_contact_title">悩んだら問い合わせください</p>
                <p className="maintenance_contact_desc">ほつれ等の故障や、着用感が出てきて気になるなど、何か気になれば<br className="hideSP"/>
                    可能な限りこちらでケア、補修をさせて頂きますのでお気軽にご連絡ください。<br/>
                    <br/>
                    <a href="https://shop.knitology.net/products/%E3%82%B9%E3%83%81%E3%83%BC%E3%83%A0%E3%82%A2%E3%82%A4%E3%83%AD%E3%83%B3-%E6%AF%9B%E7%8E%89%E5%8F%96%E3%82%8A">■アイロンケア
                        500円</a><br/>
                    <a href="https://shop.knitology.net/products/%E3%81%BB%E3%81%A4%E3%82%8C%E4%BF%AE%E7%90%86">■ほつれ直し + アイロンケア 700円</a><br/>
                    + 返送用送料700円</p>
                <p className="maintenance_contant_online"><a href="https://shop.knitology.net/collections/care-maintenance-%E4%BF%AE%E7%90%86">オンラインストア</a>にて補修用商品がございますので、<br className="hidePC"/> そちらをご購入ください。</p>
            </div>
        </Container>
    );
}

const Container = styled.div`

  padding-bottom: 240px;

  .maitaenance-image {
    //width: 100%;
    margin: 0 auto;
  }

  .maitenance_sp_image {
    text-align: center;
    margin: 0 auto;
    width: 100%;
  }

  .section-title-jp {
    //font-family: YuMincho;
    font-weight: 500;
    font-size: 40px;
    letter-spacing: 0.1em;
    line-height: 93px;
    text-align: center;
    color: #2F2F2F;

    @media (max-width: 768px) {
      font-size: 5.5vw;
    }
  }

  .section-desc {
    //font-family: YuMincho;
    font-weight: 500;
    font-size: 18px;
    letter-spacing: 0.05em;
    line-height: 37px;
    text-align: center;
    color: #2F2F2F;

    @media (max-width: 768px) {
      font-size: 4vw;
      line-height: 1.6em;
      margin: 0 20px;
    }
  }

  .maintenance {
    max-width: 1120px;
    min-height: 880px;
    margin: 0 auto;
    position: relative;

    .maintenance-bg {
      position: absolute;
      margin: 0 auto;
      width: 100%;
    }


    .maintenance-prot-desc {
      position: absolute;
      max-width: 300px;
      color: #2F2F2F;

      @media (max-width: 768px) {
        position: relative;
        margin: 30px auto;
        max-width: 600px;
        
        ul {
          margin: 0 20px 0 40px;
        }
      }

      .maintenance-prot-num {
        display: block;
      }

      .maintenance-topic-title {
        display: block;
        //font-family: YuMincho;
        font-weight: 500;
        font-size: 22px;
        letter-spacing: 0.05em;
        line-height: 28px;
        text-align: center;
        color: #2F2F2F;
        margin-bottom: 20px;
      }

      .maintenance-topic-list {
        font-size: 14px;
        letter-spacing: 0.05em;
        line-height: 24px;
        text-align: left;
        color: #31261f;
      }

      .maintenance-topic-list:before {
        display: inline-block;
        vertical-align: middle;
        content: '';
        background: #2F2F2F;
        border-radius: 50%;
        margin-left: -10px;
        position: relative;
        left: -10px;
        top: -2px;
        width: 10px;
        height: 10px;
      }
    }

    .content {


      @media (min-width: 769px) {
        &__0 {
          top: 80px;
          left: 80px;
        }

        &__1 {
          top: 120px;
          right: 40px;
        }

        &__2 {
          top: 440px;
          left: 40px;
        }
      }

    }
  }

  .maintenance_contact {
    margin-top: 120px;
    text-align: center;

    .maintenance_contact_title {
      font-weight: 500;
      font-size: 22px;
      font-size: min(22px, 4.5vw);
      letter-spacing: 0.05em;
      line-height: 28px;
      text-align: center;
      color: #31261f;

    }

    .maintenance_contact_desc {
      margin-top: 20px;
      font-weight: 500;
      font-size: 14px;
      font-size: min(14px, 3.5vw);
      letter-spacing: 0.05em;
      line-height: 28px;
      text-align: center;
      color: #31261f;

      @media (max-width: 768px) {
        padding: 0 20px;
        max-width: 400px;
        margin: 20px auto
      }

      a {
        text-decoration: underline #9B8750;
        &:hover {
          text-decoration: none;
        }
      }
    }

    .maintenance_contant_online {
      margin-top: 20px;
      font-weight: 500;
      font-size: 12px;
      letter-spacing: 0.05em;
      line-height: 20px;
      color: #31261f;


      a {
        text-decoration: underline #9B8750;
        &:hover {
          text-decoration: none;
        }
      }
    }
  }
`